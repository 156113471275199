import Header from "./components/header";
import Editor from "./components/editor";

function App() {
  return (
    <div className="app">
      <Header />
      <Editor />
    </div>
  );
}

export default App;
