import { useState } from "react";

const MenuBar = ({ editor }) => {
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  const handleUploadAndInsert = () => {
    console.log(editor.state.selection.$anchor.pos);

    const url =
      "https://res.cloudinary.com/sivadass/image/upload/v1493620047/dummy-products/onion.jpg";
    editor.chain().focus().setImage({ src: url }).run();
    setIsImageDialogOpen(false);
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="menubar-wrapper">
      <div className="menubar">
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          <span className="material-icons">title</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <span class="material-icons">format_bold</span>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <span class="material-icons">format_italic</span>
        </button> */}
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <span class="material-icons">strikethrough_s</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          className={editor.isActive("highlight") ? "is-active" : ""}
        >
          <span class="material-icons">highlight_alt</span>
        </button>
        {/* <button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
        >
          <span class="material-icons">format_align_left</span>
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
        >
          <span class="material-icons">format_align_center</span>
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        >
          <span class="material-icons">format_align_right</span>
        </button> */}
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={editor.isActive("code") ? "is-active" : ""}
        >
          <span className="material-icons">code</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <span class="material-icons">format_list_bulleted</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <span class="material-icons">format_list_numbered</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
        >
          <span class="material-icons">integration_instructions</span>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          <span class="material-icons">format_quote</span>
        </button>
        <button
          onClick={() => {
            setIsImageDialogOpen(true);
          }}
          className={isImageDialogOpen ? "is-active" : ""}
        >
          <span class="material-icons">add_photo_alternate</span>
        </button>
      </div>
      {isImageDialogOpen && (
        <div className="image-dialog">
          <div>
            <p>Drag and drop or choose an image</p>
            <button onClick={() => handleUploadAndInsert()}>
              Upload and insert
            </button>
            <button onClick={() => setIsImageDialogOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuBar;
